<template>
  <div class="content pantone d-flex flex-col">
    <h1>Pantone</h1>
    <div class="flex flex-col gap-4">
      <h2 class="font-bold">Showing {{ colors.length }} color(s)</h2>
      <div class="filters flex gap-4 justify-between">
        <div class="flex gap-2">
          <b-form-input
            v-model="filters.search"
            placeholder="Search"
            size="sm"
            autocomplete="off"
            trim
            type="search"
            debounce="500"
            @change="fetchColors()"
          ></b-form-input>

          <b-form-input
            class="minwax-filter"
            v-model="filters.minwax_from"
            placeholder="Wax (Min)"
            size="sm"
            autocomplete="off"
            trim
            type="number"
            debounce="500"
            @change="fetchColors()"
          ></b-form-input>

          <b-form-input
            class="minwax-filter"
            v-model="filters.minwax_to"
            placeholder="Wax (Max)"
            size="sm"
            autocomplete="off"
            trim
            type="number"
            debounce="500"
            @change="fetchColors()"
          ></b-form-input>

          <button-select
            v-model="filters.sort"
            :options="options.sort"
            size="sm"
            @input="fetchColors()"
          ></button-select>

          <button-select
            v-model="filters.is_favorite"
            :options="options.is_favorite"
            size="sm"
            @input="fetchColors()"
          ></button-select>

          <button-select
            v-model="filters.wax_type"
            :options="options.wax_type"
            size="sm"
            @input="fetchColors()"
          ></button-select>

        </div>

        <div class="flex gap-2">
          <b-button
            variant="primary"
            size="sm"
            @click="openMixing()"
          >
            Corob
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            @click="exportColors()"
            :disabled="loadingCount > 0"
          >
            Export
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            @click="resetFilters()"
            :disabled="loadingCount > 0"
          >
            Reset
          </b-button>
        </div>
      </div>

      <div class="colors">
        <div
          v-for="color in colors"
          :key="color.id"
          class="color d-flex flex-column justify-content-between"
          :class="{ 'favorite': color.is_favorite }"
          :style="{
            'background-color': `#${color.hex}`,
            'color': isColorDark(color.hex) ? 'white' : 'dark'
          }"
        >
          <div class="px-2 py-1">Pantone {{ color.description }}</div>
          <div class="px-2 flex gap-1 justify-between text-nowrap">
            <small>Page: {{ color.page }}</small>
            <small>MW: {{ color.minwax_french | format }} g</small>
          </div>
          <div class="d-flex justify-content-between">
            <button
              @click="openRecipe(color)"
              title="Open recipe"
              class="recipe-button px-1.5"
            >
              <i class="material-icons">list_alt</i>
            </button>

            <button
              class="favorite-button px-2 py-1"
              @click="toggleFavorite(color)"
              :disabled="color.busy"
            >
              <i v-if="color.is_favorite" class="fas fa-heart"></i>
              <i v-else class="far fa-heart"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <mixing-modal
      v-if="mixingModal.showModal"
      :data="mixingModal"
      :filters="filters"
      @apply="onMixingApply"
    />

    <recipe-modal
      v-if="recipeModal.showModal"
      :data="recipeModal"
    />
  </div>
</template>

<script>
import {
  downloadFile,
  isColorDark,
} from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const MixingModal = () => import('@/components/colors/MixingModal.vue');
const RecipeModal = () => import('@/components/design/RecipeModal.vue');

export default {
  name: 'Pantone',
  components: {
    ButtonSelect,
    MixingModal,
    RecipeModal,
  },
  computed: {
    queryFilters() {
      return {
        type: this.colorType,
        mixing_french: this.mixing.french.join(','),
        mixing_bekro: this.mixing.bekro.join(','),
        ...this.filters,
      };
    },
  },
  data() {
    return {
      colors: [],
      favorite: [],
      filters: {
        has_recipe_bekro: '',
        has_recipe_french: '1',
        is_favorite: '',
        wax_type: '',
        page: 1,
        page_source: '',
        perPage: 9999,
        search: '',
        similar: null,
        mixing_mode: 'exclusive',
        minwax_from: '',
        minwax_to: '1000',
        sort: 'pantoneNumber',
      },
      options: {
        is_favorite: [
          { text: 'All', value: '' },
          { text: 'Favorite', value: '1' },
        ],
        sort: [
          { text: 'Shade', value: 'default' },
          { text: 'Pantone #', value: 'pantoneNumber' },
          { text: 'Pantone Page', value: 'pantonePage' },
        ],
        wax_type: [
          { text: 'All', value: '' },
          { text: 'Paraffin', value: 'paraffin' },
          { text: 'Soy', value: 'soy' },
          { text: 'Blend', value: 'blend' },
        ],
      },
      mixing: {
        french: [
          'red_20002',
          'yellow_20003',
          'blue_20004',
          'black_20005',
          'green_20006',
          'brown_20008',
          'orange_20009',
          'magenta_20010',
        ],
        bekro: [],
      },
      mixingModal: {
        showModal: false,
        mixing: {},
      },
      recipeModal: {
        showModal: false,
        color: null,
      },
    };
  },
  methods: {
    isColorDark(hex) {
      return isColorDark(hex);
    },
    exportColors() {
      this.loadingCount++;
      this.$http
        .get('/color_export')
        .query(this.queryFilters)
        .then((res) => {
          downloadFile(res.body.link);
        })
        .catch((err) => {
          this.$toast.error(`Failed to export colors: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchColors() {
      this.loadingCount++;
      this.$http
        .get('/color')
        .query(this.queryFilters)
        .then((res) => {
          res.body.colors.forEach((color) => {
            color.busy = false;
          });
          this.colors = res.body.colors;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch colors: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchFavorite() {
      this.$http
        .get('/color_favorite')
        .then((res) => {
          this.favorite = res.body.favorite;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch favorite: ${err.response.text}`);
        });
    },
    toggleFavorite(color) {
      color.busy = true;
      color.is_favorite = !color.is_favorite;
      this.$http
        .post('/color_favorite')
        .send({ color_id: color.id })
        .send({ state: color.is_favorite ? 1 : 0 })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(`Failed to set favorite: ${err.response.text}`);
          color.is_favorite = !color.is_favorite;
        })
        .finally(() => {
          color.busy = false;
        });
    },
    onMixingApply(mixing) {
      this.mixing = mixing;
      this.fetchColors();
    },
    resetFilters() {
      this.mixing.french = [
        'red_20002',
        'yellow_20003',
        'blue_20004',
        'black_20005',
        'green_20006',
        'brown_20008',
        'orange_20009',
        'magenta_20010',
      ];
      this.mixing.bekro = [];
      this.filters.is_favorite = '';
      this.filters.search = '';
      this.filters.mixing_mode = 'exclusive';
      this.filters.minwax_from = '';
      this.filters.minwax_to = '1000';
      this.filters.sort = 'pantoneNumber';
      this.fetchColors();
    },
    openRecipe(color) {
      this.recipeModal.color = color;
      this.recipeModal.showModal = true;
    },
    openMixing() {
      this.mixingModal.mixing = this.mixing;
      this.mixingModal.showModal = true;
    },
  },
  created() {
    this.fetchColors();
    this.fetchFavorite();
  },
};
</script>

<style lang="scss" scoped>
  .pantone {
    max-width: 1350px;
    margin: 0 auto;
  }

  .colors {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 12px;
  }

  .color {
    height: 80px;
  }

  .color:hover .favorite-button,
  .color.favorite .favorite-button,
  .color:hover .recipe-button {
    visibility: visible;
  }

  .favorite-button,
  .recipe-button {
    visibility: hidden;
  }

  .minwax-filter {
    width: 120px;
  }
</style>
